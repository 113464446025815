
.Todo {
    &__list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 280px);
        overflow: auto;

        > .__no-todos {
            text-align: center;
            font-size: 20px;
            color: #bebebe;
            padding: 15px 20px;
        }
    }
}

