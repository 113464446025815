.Todo {
    &__creation {
        display: flex;

        &__input {
            flex: 1 1;
            padding: 16px 20px;
            font-size: 24px;
            box-shadow: inset 0 -2px 1px rgb(0 0 0 / 3%);
        }
    }
}
