:root {
    --accent-color: $accentColor;
}

* {
    outline: none;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    background: $bgColor;
    color: $bodyColor;
    min-width: 230px;
    max-width: 550px;
    margin: 0 auto;
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: 3px;
    min-height: 32px;
    min-width: 60px;
    padding: 4px 8px;
    line-height: inherit;
    cursor: pointer;
    transition: .3s;

    &:hover {
        opacity: 0.85;
    }
}

input {
    min-height: 40px;
    border: none;
    outline: none;
    padding: 14px 20px;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    transition: .3s;

    @include placeholder-style(#cbcbcb);

    &:focus {
    }

    &[type="checkbox"] {
        width: 40px;
        height: 40px;
        box-shadow: none;
        border: none;
        outline: none;
        cursor: pointer;
        appearance: none;
        background: transparent;
        background-repeat: no-repeat;
        background-position: center left;
        background-image: url('data:image/svg+xml,%0A%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-10 -18 100 135"%3E%3Ccircle cx="50" cy="50" r="50" fill="none" stroke="#{rgba($accentColor,0.99)}" stroke-width="5"/%3E%3C/svg%3E');
        margin: 0;

        &:checked {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-10 -18 100 135"%3E%3Ccircle cx="50" cy="50" r="50" fill="none" stroke="#{rgba($accentColor,0.99)}" stroke-width="5"/%3E%3Cpath fill="#{rgba($accentColor,0.99)}" d="M72 25L42 71 27 56l-4 4 20 20 34-52z"/%3E%3C/svg%3E');
        }

        &:focus {
            box-shadow: none;
            border: none;
            outline: none;
        }
    }
}

