@function em($pixels, $context: 16) {
    @return #{$pixels / $context}em
}

@mixin placeholder-style($color) {
    /* WebKit, Edge */
    &::-webkit-input-placeholder {
        color: $color;
    }
    /* Firefox 4-18 */
    &:-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    /* Firefox 19+ */
    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    /* IE 10-11 */
    &:-ms-input-placeholder {
        color: $color;
    }
    /* Edge */
    &::-ms-input-placeholder {
        color: $color;
    }
    /* MODERN BROWSER */
    &::placeholder {
        color: $color;
    }
}
